import { EntityUpdateNotification } from '@core/models/entity-update-notification.model';

export const ENTITY_UPDATES_ACTION_NAME_PREFIX = '[Entity Updates]';

export class UpdateEntityUpdates {
  static readonly type = `${ENTITY_UPDATES_ACTION_NAME_PREFIX} Update Entity Updates`;
  constructor(public entityUpdates: EntityUpdateNotification[]) {}
}

export class ClearEntityUpdates {
  static readonly type = `${ENTITY_UPDATES_ACTION_NAME_PREFIX} Clear Entity Updates`;
}

export class ListenForEntityUpdates {
  static readonly type = `${ENTITY_UPDATES_ACTION_NAME_PREFIX} Listen For Entity Updates`;
  constructor(
    public entityId: string,
    public orgId?: string
  ) {}
}

export class StopListeningForEntityUpdates {
  static readonly type = `${ENTITY_UPDATES_ACTION_NAME_PREFIX} Stop Listening For Entity Updates`;
}
